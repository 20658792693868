import {
	faGear,
	faIdCard,
	faPersonToDoor,
} from "@fortawesome/pro-solid-svg-icons";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteChange } from "~/hooks/use-route-change";
import { useLogout, useUser } from "~/hooks/use-user";
import { Anchor } from "../interactive/Anchor";
import { Button } from "../interactive/Button";
import { Link } from "@tanstack/react-router";

export function UserAvatar() {
	const user = useUser();
	const logout = useLogout();
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	useRouteChange(() => setOpen(false));

	return (
		<DropdownMenu.Root modal={false} open={open} onOpenChange={setOpen}>
			<DropdownMenu.Trigger className="theme-focus-visible z-1 flex aspect-square w-8 items-center justify-center rounded-full bg-gray-3 font-bold text-gray-11 text-sm xs:w-10 hover:bg-gray-4 xs:text-base safe-motion:transition-colors">
				{user.data.firstname.at(0)?.toUpperCase()}
				{user.data.lastname.at(0)?.toUpperCase()}
			</DropdownMenu.Trigger>

			<DropdownMenu.Content
				className="flex min-w-40 flex-col items-start gap-1 rounded bg-gray-3 px-4 py-2 shadow"
				align="end"
			>
				<span className="pointer-events-none absolute right-0 bottom-[calc(100%-0.5rem)] h-[calc(2rem+0.5rem)] w-[2rem] rounded-t-full bg-gray-3 xs:h-[calc(2.5rem+0.5rem)] xs:w-[2.5rem]" />

				<span className="mb-1 text-gray-11 text-sm">
					Hoi {user.data.firstname}
				</span>

				<Anchor asChild variant="link" color="dark" startIcon={faIdCard}>
					<Link to="/profile">Profiel</Link>
				</Anchor>

				<Button variant="link" color="dark" startIcon={faGear}>
					Instellingen
				</Button>

				<Button
					variant="link"
					color="dark"
					onClick={() => logout.mutate()}
					startIcon={faPersonToDoor}
					isLoading={logout.isPending}
				>
					{t("logout")}
				</Button>
			</DropdownMenu.Content>
		</DropdownMenu.Root>
	);
}
