import ky from "ky";

const BASE_URL = import.meta.env.VITE_APP_URL;

let csrfToken: string | undefined = undefined;

function updateCsrfToken() {
	const cookie = document.cookie
		.split("; ")
		.find((row) => row.startsWith("XSRF-TOKEN="))
		?.split("=");

	if (cookie?.[1]) {
		csrfToken = decodeURIComponent(cookie[1]);
	}

	return csrfToken;
}

export const api = ky.create({
	prefixUrl: BASE_URL,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
	credentials: "include",
	// let react-query handle retries
	retry: 0,
	hooks: {
		beforeRequest: [
			(request) => {
				if (csrfToken) {
					request.headers.set("X-XSRF-TOKEN", csrfToken);
				}

				return request;
			},
		],
		beforeError: [
			async (error) => {
				const json = await error.response
					.clone()
					.json()
					.catch(() => null);

				if (json.message) {
					error.message = json.message;
				}

				return error;
			},
		],
		afterResponse: [
			async (request, options, response) => {
				if (response.status === 419) {
					await ky.get(`${BASE_URL}sanctum/csrf-cookie`, {
						credentials: "include",
					});

					return ky(request, {
						...options,
						headers: { ...options.headers, "X-XSRF-TOKEN": updateCsrfToken() },
					});
				}

				updateCsrfToken();
			},
		],
	},
});

export type PaginatedResponse<T> = {
	data: T[];
	meta: {
		current_page: number;
		from: number;
		last_page: number;
		links: {
			url: string | null;
			label: string;
			active: boolean;
		}[];
		path: string;
		per_page: number;
		to: number;
		total: number;
	};
};
