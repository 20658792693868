import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Portal } from "@radix-ui/react-portal";
import { clsx } from "clsx";
import { useRef, useState } from "react";
import { useOnClickOutside, useScrollLock } from "usehooks-ts";
import { useEscapeKeydown } from "../../../../hooks/use-escape-keydown";
import { useRouteChange } from "../../../../hooks/use-route-change";
import { Icon } from "../../IconVariant";
import { NavigationContent } from "./Content";

export function MobileNavigation() {
	const [open, setOpen] = useState<boolean>(false);

	const mainRef = useRef<HTMLDivElement>(null);

	const { lock, unlock } = useScrollLock({ autoLock: false });

	function onOpenChange(open: boolean) {
		setOpen(open);

		if (open) {
			lock();
		} else {
			unlock();
		}
	}

	useOnClickOutside(mainRef, () => onOpenChange(false));
	useRouteChange(() => onOpenChange(false));
	useEscapeKeydown(() => onOpenChange(false));

	return (
		<>
			<button
				type="button"
				onClick={() => onOpenChange(!open)}
				className="theme-focus-visible flex aspect-square w-8 shrink-0 items-center justify-center rounded-full xs:hidden hover:bg-gray-2 safe-motion:transition-colors"
			>
				<Icon icon={faBars} />
			</button>

			<Portal>
				<div
					ref={mainRef}
					className={clsx(
						"fixed inset-0 z-2 flex w-full flex-col gap-6 bg-background p-4 xs:hidden",
						{ "-left-full": !open },
					)}
				>
					<div className="flex items-center justify-between gap-4">
						<img
							src="/images/logo-icon.png"
							alt="TOSIZE Hemlock"
							className="h-12 w-12"
						/>

						<button
							type="button"
							onClick={() => onOpenChange(false)}
							className="theme-focus-visible aspect-square w-8 appearance-none rounded-full text-gray-11 hover:bg-gray-2 safe-motion:transition-colors"
						>
							<Icon icon={faTimes} />
						</button>
					</div>
					<NavigationContent expanded />
				</div>
			</Portal>
		</>
	);
}
