import { faMoonStars, faSunBright } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { Icon } from "../IconVariant";

export function DarkLightToggle() {
	const systemMotion = useMediaQuery("(prefers-reduced-motion: reduce)");
	const systemDark = useMediaQuery("(prefers-color-scheme: dark)");
	const [state, setState] = useState<"dark" | "light">(
		systemDark ? "dark" : "light",
	);

	useEffect(() => {
		const isDark =
			document.documentElement.getAttribute("data-theme") === "dark";

		setState(isDark ? "dark" : "light");
	}, []);

	return (
		<button
			title="Thema wisselen"
			type="button"
			className="theme-focus-visible aspect-square w-8 rounded-full text-gray-11 xs:w-10 hover:bg-gray-2 safe-motion:transition-colors"
			onClick={async () => {
				document.documentElement.setAttribute("data-motion", "reduce");

				setState((prev) => (prev === "light" ? "dark" : "light"));

				document.documentElement.setAttribute(
					"data-theme",
					state === "light" ? "dark" : "light",
				);

				localStorage.setItem("theme", state === "light" ? "dark" : "light");

				setTimeout(() => {
					document.documentElement.setAttribute(
						"data-motion",
						systemMotion ? "reduce" : "no-preference",
					);
				}, 150); // this should match the maximum transition duration used in project
			}}
		>
			<Icon icon={state === "light" ? faSunBright : faMoonStars} />
		</button>
	);
}
