import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

// @niels this imports the whole tailwindcss lib into the bundle
// export function useTailwindConfig() {
// 	return resolveConfig(tailwindConfig);
// }

export function cn(...args: ClassValue[]) {
	return twMerge(clsx(args));
}
