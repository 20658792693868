import {
	queryOptions,
	useMutation,
	useQueryClient,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { api } from "../query";

interface User {
	id: string;
	firstname: string;
	lastname: string;
	username: string;
	email?: string;
	created_at: string;
	updated_at: string;
}

export const userQueryOptions = queryOptions({
	queryKey: ["me"],
	queryFn: () => api.get("api/v1/me").json<User>(),
});

export const useUser = () => useSuspenseQuery(userQueryOptions);

export const useLogout = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return useMutation({
		mutationFn: () => api.post("api/v1/logout"),
		onSuccess: async () => {
			await navigate({ to: "/login", search: { redirect: "/" } });

			await queryClient.invalidateQueries();
		},
	});
};
