import { useEffect } from "react";

export function useEscapeKeydown(callback: () => void) {
	useEffect(() => {
		const handleKeydown = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				callback();
			}
		};

		window.addEventListener("keydown", handleKeydown);

		return () => window.removeEventListener("keydown", handleKeydown);
	}, [callback]);

	return null;
}
