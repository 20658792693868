import { useRouter } from "@tanstack/react-router";
import { useEffect } from "react";

export function useRouteChange(callback: () => void) {
	const router = useRouter();

	useEffect(() => {
		const unSubscribe = router.subscribe("onBeforeLoad", callback);

		return () => {
			unSubscribe();
		};
	}, [router, callback]);

	return null;
}
