import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { Slot, Slottable } from "@radix-ui/react-slot";
import type React from "react";
import { forwardRef } from "react";
import { Icon, type IconProps } from "../IconVariant";
import {
	type AnchorVariantProps,
	type ButtonVariantProps,
	type InteractiveIconProps,
	type TypeProps,
	interactiveAnchor,
	interactiveButton,
} from "./index";

interface BaseProps {
	asChild?: boolean;
	isLoading?: boolean;
}

export type ButtonProps = BaseProps &
	InteractiveIconProps &
	TypeProps &
	React.JSX.IntrinsicElements["button"];

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	function ButtonRef(props, ref) {
		const {
			variant,
			size,
			color,

			startIcon,
			endIcon,
			iconSize,
			iconColor,

			asChild,
			isLoading,

			children,
			className,
			...restProps
		} = props;

		const Comp = asChild ? Slot : "button";

		return (
			<Comp
				ref={ref}
				disabled={isLoading}
				className={
					variant === "link"
						? interactiveAnchor({
								color: color as AnchorVariantProps["color"],
								className,
							})
						: interactiveButton({
								variant: variant as ButtonVariantProps["variant"],
								size: size as ButtonVariantProps["size"],
								color: color as ButtonVariantProps["color"],
								className,
							})
				}
				{...restProps}
			>
				{(startIcon || (!startIcon && !endIcon && isLoading)) && (
					<Icon
						icon={isLoading ? faSpinnerThird : (startIcon as IconProps["icon"])}
						size={iconSize}
						color={iconColor}
						loading={isLoading}
						className={children ? "mr-2" : undefined}
					/>
				)}

				<Slottable>{children}</Slottable>

				{endIcon && (
					<Icon
						icon={endIcon}
						size={iconSize}
						color={iconColor}
						className={children ? "ml-2" : undefined}
					/>
				)}
			</Comp>
		);
	},
);
