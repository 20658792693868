import {
	faBoxesPacking,
	faCaretDown,
	faCaretUp,
	faChartUser,
	faCircle1,
	faListCheck,
	faRectangleHistoryCirclePlus,
	faRectanglesMixed,
	faTruckFast,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "@tanstack/react-router";
import {
	type ComponentProps,
	type ReactNode,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { cn } from "~/hooks/use-tailwind";
import { Icon, type IconProps } from "../../IconVariant";
import { Button } from "../../interactive/Button";

interface ContentProps {
	expanded: boolean;
	toggleable?: boolean;
}

export function NavigationContent({
	expanded,
	toggleable = false,
}: ContentProps) {
	const { t } = useTranslation();

	return (
		<>
			<NavigationItem icon={faChartUser} to="/" expanded={expanded}>
				{expanded ? t("dashboard") : null}
			</NavigationItem>

			<ToggleNavigation
				expanded={expanded}
				toggleable={toggleable}
				title="Orders"
				defaultOpen
				items={[
					{ to: "/orders", icon: faListCheck, title: t("orders") },
					{
						to: "/orders",
						icon: faRectangleHistoryCirclePlus,
						title: "Inkoop",
					},
					{ to: "/orders", icon: faRectanglesMixed, title: "Optimaliseren" },
					{ to: "/orders", icon: faTruckFast, title: "Shipments" },
				]}
			/>

			<ToggleNavigation
				expanded={expanded}
				toggleable={toggleable}
				title="Favourites"
				defaultOpen
				items={[
					{ to: "./orders", icon: faBoxesPacking, title: "Shipments / DPD" },
					{ to: "./orders", icon: faCircle1, title: "Zaag #1" },
				]}
			/>
		</>
	);
}

function ToggleNavigation(props: {
	expanded: boolean;
	toggleable: boolean;
	title: string;
	defaultOpen?: boolean;
	items: {
		to: ComponentProps<typeof Link>["to"];
		icon: IconProps["icon"];
		title: string;
	}[];
}) {
	const { expanded, toggleable, title, items, defaultOpen = false } = props;
	const [open, setOpen] = useState(defaultOpen);

	useEffect(() => {
		if (!expanded) {
			setOpen(true);
		}
	}, [expanded]);

	return (
		<div className="flex flex-col gap-2">
			{expanded ? (
				<>
					{toggleable ? (
						<Button
							className="text-left"
							color="gray"
							variant="link"
							endIcon={open ? faCaretDown : faCaretUp}
							onClick={() => setOpen(!open)}
						>
							{title}
						</Button>
					) : (
						<span className="text-gray-9">{title}</span>
					)}
				</>
			) : (
				<hr className="mb-4 text-gray-6" />
			)}

			{open &&
				items.map((item) => (
					<NavigationItem
						key={item.title}
						icon={item.icon}
						to={item.to}
						expanded={expanded}
					>
						{expanded ? item.title : null}
					</NavigationItem>
				))}
		</div>
	);
}

function NavigationItem(props: {
	to: ComponentProps<typeof Link>["to"];
	children?: ReactNode;
	icon: IconProps["icon"];
	expanded: boolean;
	className?: string;
}) {
	const { to, children, icon, expanded, className } = props;

	return (
		<Link
			to={to}
			className={cn(
				"flex items-center rounded text-lg outline-none data-[status=active]:bg-gray-2 hover:bg-gray-2 focus-visible:ring-1 focus-visible:ring-primary-7 focus-visible:ring-inset safe-motion:transition-colors",
				className,
				{
					"px-3 py-2.5": expanded,
					"h-12 w-12 justify-center": !expanded,
				},
			)}
		>
			{({ isActive }) => (
				<>
					<Icon
						icon={icon}
						color={isActive ? "primary" : "dark"}
						className={expanded ? "mr-4" : ""}
					/>
					{children}
				</>
			)}
		</Link>
	);
}
