import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react"

// Import the generated route tree
import { routeTree } from "./routeTree.gen";

import { HTTPError } from "ky";
import "../css/app.css";
import "./i18n";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (failureCount, error) => {
				if (
					error instanceof HTTPError &&
					[401, 419, 422].includes(error.response.status)
				) {
					return false;
				}

				return failureCount < 1;
			},
		},
	},
});

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [Sentry.browserTracingIntegration()],
	tracesSampleRate: 0.1,
});

// Create a new router instance
const router = createRouter({
	routeTree,
	context: {
		queryClient,
	},
	defaultPreload: "intent",
	// Since we're using React Query, we don't want loader calls to ever be stale
	// This will ensure that the loader is always called when the route is preloaded or visited
	defaultPreloadStaleTime: 0,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

// Render the app
const rootElement = document.getElementById("root");

if (rootElement && !rootElement?.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);

	root.render(
		<StrictMode>
			<QueryClientProvider client={queryClient}>
				<Suspense fallback={null}>
					<RouterProvider router={router} />
				</Suspense>
			</QueryClientProvider>
		</StrictMode>,
	);
}
