import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	useDebounceValue,
	useOnClickOutside,
	useScrollLock,
} from "usehooks-ts";
import { useEscapeKeydown } from "~/hooks/use-escape-keydown";
import { useRouteChange } from "~/hooks/use-route-change";
import { api } from "~/query";
import type { Order } from "~/types/Order";
import { Icon } from "../IconVariant";

export function SearchBar() {
	const mainRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const resultsRef = useRef<HTMLDivElement>(null);
	const [query, setQuery] = useState("");
	const [debouncedQuery, setDebouncedQuery] = useDebounceValue(query, 200);

	const { t } = useTranslation();
	const { lock, unlock } = useScrollLock({ autoLock: false });

	function cancel() {
		setQuery("");
		setDebouncedQuery("");
	}

	useOnClickOutside(mainRef, cancel);
	useRouteChange(cancel);
	useEscapeKeydown(cancel);

	const searchQuery = useQuery({
		queryKey: ["search", debouncedQuery],
		queryFn: ({ signal }) => {
			return api
				.get(`api/v1/search/${debouncedQuery}`, { signal })
				.json<{ orders: Order[] }>();
		},
		placeholderData: keepPreviousData,
		enabled: !!debouncedQuery,
	});

	const isActive = useMemo(
		() => !!query && !!searchQuery.data,
		[query, searchQuery.data],
	);

	useEffect(() => {
		if (isActive) {
			document.documentElement.style.setProperty("--dialog-overlay", "block");
			lock();
		} else {
			document.documentElement.style.removeProperty("--dialog-overlay");
			unlock();
		}

		return () => {
			document.documentElement.style.removeProperty("--dialog-overlay");
			unlock();
		};
	}, [isActive, lock, unlock]);

	return (
		<div ref={mainRef} className="w-full grow py-2">
			<label className="relative flex h-8 w-full items-center gap-4 xs:h-10">
				<Icon icon={faMagnifyingGlass} />
				<input
					ref={inputRef}
					type="search"
					placeholder={`${t("search")}...`}
					className="h-full w-full appearance-none bg-transparent outline-none focus-visible:placeholder:text-gray-11/70 placeholder:text-gray-11/50 safe-motion:transition-colors"
					value={query}
					onChange={(e) => {
						setQuery(e.target.value);
						setDebouncedQuery(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "ArrowDown" || e.key === "ArrowRight") {
							e.preventDefault();

							if (
								resultsRef.current?.firstElementChild instanceof HTMLElement
							) {
								resultsRef.current.firstElementChild.focus();
							}
						}
					}}
				/>
			</label>

			{isActive && (
				<div
					ref={resultsRef}
					className="scrollbar-none absolute top-full right-0 left-0 max-h-[calc(60vh-var(--header-y))] snap-y overflow-y-auto border-gray-6 border-t border-b bg-background *:snap-start"
				>
					{!searchQuery.data?.orders.length && (
						<div className="px-4 py-2">{t("no_search_results")}</div>
					)}

					{searchQuery.data?.orders.map((order) => (
						<Link
							key={order.id}
							to="/orders/$id"
							params={{ id: order.id }}
							className="flex flex-wrap gap-4 px-4 py-2 outline-none even:bg-gray-2 focus-visible:bg-primary-4 hover:bg-gray-4 safe-motion:transition-colors"
							onKeyDown={(e) => {
								const keys = [
									"ArrowUp",
									"ArrowRight",
									"ArrowDown",
									"ArrowLeft",
								];

								if (keys.includes(e.key)) {
									e.preventDefault();

									if (e.target instanceof HTMLElement) {
										const sibling =
											e.key === "ArrowDown" || e.key === "ArrowRight"
												? e.target.nextElementSibling
													? e.target.nextElementSibling
													: e.target.parentElement?.firstElementChild
												: e.key === "ArrowUp" || e.key === "ArrowLeft"
													? e.target.previousElementSibling
														? e.target.previousElementSibling
														: inputRef.current
													: null;

										if (sibling instanceof HTMLElement) {
											sibling.focus();
										}
									}
								}
							}}
						>
							<div className="container flex flex-wrap items-center gap-x-4">
								<span
									className="font-mono text-gray-11"
									// biome-ignore lint/security/noDangerouslySetInnerHtml: This is intentional
									dangerouslySetInnerHTML={{
										__html: order.increment_id.replace(
											debouncedQuery,
											`<b>${debouncedQuery}</b>`,
										),
									}}
								/>
							</div>
						</Link>
					))}
				</div>
			)}
		</div>
	);
}
