import { Slot, Slottable } from "@radix-ui/react-slot";
import type { JSX } from "react";
import { forwardRef } from "react";
import { Icon } from "../IconVariant";
import {
	type AnchorVariantProps,
	type ButtonVariantProps,
	type InteractiveIconProps,
	type TypeProps,
	interactiveAnchor,
	interactiveButton,
} from "./index";

interface BaseProps {
	asChild?: boolean;
}

export type AnchorProps = BaseProps &
	InteractiveIconProps &
	TypeProps &
	JSX.IntrinsicElements["a"];

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
	function AnchorRef(props, ref) {
		const {
			variant = "link",
			size,
			color,

			startIcon,
			endIcon,
			iconSize,
			iconColor,

			asChild,

			children,
			className,
			...restProps
		} = props;

		const Comp = asChild ? Slot : "a";

		return (
			<Comp
				ref={ref}
				className={
					variant === "link"
						? interactiveAnchor({
								color: color as AnchorVariantProps["color"],
								className,
							})
						: interactiveButton({
								variant: variant as ButtonVariantProps["variant"],
								size: size as ButtonVariantProps["size"],
								color: color as ButtonVariantProps["color"],
								className,
							})
				}
				rel={props.target === "_blank" && !props.rel ? "noopener" : props.rel}
				{...restProps}
			>
				{startIcon && (
					<Icon
						icon={startIcon}
						size={iconSize}
						color={iconColor}
						className={children ? "mr-2" : undefined}
					/>
				)}

				<Slottable>{children}</Slottable>

				{endIcon && (
					<Icon
						icon={endIcon}
						size={iconSize}
						color={iconColor}
						className={children ? "ml-2" : undefined}
					/>
				)}
			</Comp>
		);
	},
);
